

































































































import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { apiBuilder } from '../../config'
import axios from 'axios'
import dayjs from 'dayjs'

const auth = namespace('auth')

@Component
export default class SupportCase extends Vue {
	private rules = {
		required: (value: string | number) => !!value || 'Không Được Trống.',
	}

	@auth.State
	private token!: string
	@auth.State
	private username!: string

	private modalFormSelect = []

	private modalForm = {
		title: null,
		orderID: null,
		description: null,
	}

	private scDialog = false

	private tableItem = {
		header: [
			{
				text: 'Tiêu Đề',
				sortable: false,
				value: 'title',
				align: 'center',
			},
			{
				text: 'Thời Gian',
				value: 'time',
				sortable: false,
				align: 'center',
			},
			{
				text: 'Trạng Thái',
				value: 'status',
				sortable: false,
				align: 'center',
			},
			{
				text: '',
				value: 'data-table-expand',
			},
		],
		items: [],
	}

	private loading = false

	private expanded = []

	//METHOD

	private getErrorList(): void {
		const apiUrl = apiBuilder('support/geterrorlist.php')

		axios
			.post(apiUrl)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error != '940') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Eror ! Please Reload!',
					})
				}

				this.modalFormSelect = res.data.data
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	private submitSupportCase(): void {
		const apiUrl = apiBuilder('support/supportcaserequest.php')

		const param = {
			params: {
				accountid: this.modalForm.orderID,
				errortype: this.modalForm.title,
				description: this.modalForm.description,
				username: this.username,
				token: this.token,
			},
		}

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error == '901') {
					sessionStorage.setItem('redirectPath', window.location.pathname)

					return this.$router.replace({
						name: 'Login',
					})
				} else if (error == '902') {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Bạn Không Sở Hữu Tài Khoản Này !',
					})
				} else if (error == '903') {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Mã Tài Khoảng Phải Là Số !',
					})
				} else if (error != '900') {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error ! Please Reload',
					})
				} else {
					this.$swal({
						icon: 'success',
						title: 'Thành Công !',
						text: 'Tạo Yêu Cầu Thành Công !',
					})

					this.getSupportCase()

					this.scDialog = false

					const a = this.$refs.form as Vue & { reset: () => boolean }
					a.reset()
				}
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	private getSupportCase(): void {
		this.loading = true

		const apiUrl = apiBuilder('support/supportcase.php')

		const param = {
			params: {
				username: this.username,
				token: this.token,
			},
		}

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error == '931') {
					sessionStorage.setItem('redirectPath', window.location.pathname)

					return this.$router.replace({
						name: 'Login',
					})
				} else if (error != '930') {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error ! Please Reload !',
					})
				} else {
					const data = res.data.data

					//eslint-disable-next-line
					this.tableItem.items = data.map((x: any) => {
						return {
							accountid: x.accountid,
							title: x.description,
							time: dayjs.unix(x.created_at).format('DD/MM/YYYY HH:mm:ss'),
							status: x.status,
							id: x.id,
							description: x.error_description,
							reply: x.reply,
						}
					})

					this.loading = false
				}
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	created() {
		this.getErrorList()
		this.getSupportCase()
	}

	mounted() {
		setInterval(() => {
			this.getSupportCase()
		}, 60000)
	}
}
